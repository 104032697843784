import DayJS from "dayjs";
import {HLS, OUTPUT_TYPE_HARDWARE, OUTPUT_TYPE_NDI, INPUT_TYPE_IP, INPUT_TYPE_DEVICE, RTSP, RTMP, TS, SRT, NDI, WEBRTC, OUTPUT_TYPE_IP, SST,VIDEO_CARD_TYPE_RIVERMAX} from "../constants";

export const formatDate = (date) => {
  let d = new Date(date);
  return `${d.getFullYear()}-${ d.getMonth() < 9 ? '0' : '' }${d.getMonth() + 1}-${ d.getDate() < 10 ? '0' : '' }${d.getDate()} ${d.toLocaleTimeString()}`;
};

// The unit symbol for the byte is the upper-case character B
export const formatFileSize = (fileSize) => {
  if(!fileSize) return null;
  if(fileSize >= 1000000000){
    return `${(fileSize/1000000000).toFixed(1)}GB`
  }
  if(fileSize >= 1000000){
    return `${(fileSize/1000000).toFixed(1)}MB`
  }
  if(fileSize >= 1000){
    return `${(fileSize/1000).toFixed(1)}KB`
  }
  return `${fileSize}B`;
};

// The unit symbol for the byte is the upper-case character B
export const formatMonitorSize = (size) => {
  if(!size) return null;
  if(size >= 1000){
    return `${(size/1000).toFixed(2)}GB`
  }
  return `${size}MB`;
};

export const inputDisplayName = (input, ipProfile) => {
  if(input.deviceName != null){
    return input.deviceName;
  }
  if(input.playbackFilename != null){
    return input.playbackFilename;
  }
  return ipProfile ? ipProfile.name : input.name;
};

export const formatInputId = (id) => {
  return "IN-" + `0${id}`.slice(-2);
}

export const formatEncoderId = (id) => {
  return "ENC-" + `0${id}`.slice(-2);
}

export const formatOutputId = (id, outputType,videoCardType) => {
  let prefix;
  switch (outputType) {
    case OUTPUT_TYPE_HARDWARE:
      if(videoCardType=== VIDEO_CARD_TYPE_RIVERMAX ){
        prefix = "SMPTE 2110-"
      }else{
        prefix = "SDI-"
      }
      
      break;
    case OUTPUT_TYPE_NDI:
      // Removing prefix (Task #15747)
      prefix = null
      break;
    default:
      // Removing prefix (Task #15747)
      prefix = null
      break;
  }
  return prefix ? prefix + `0${id}`.slice(-2) : null;
}

export const inputIcon = (inputType, channelType) => {
  if (inputType === INPUT_TYPE_IP) {
    switch (channelType) {
      case RTSP:
        return "rtsp_input";
      case RTMP:
        return "rtmp_input";
      case HLS:
        return "hls_input";
      case TS:
        return "ts_input";
      case SRT:
        return "srt_input";
      case NDI:
        return "ndi_input";
      case WEBRTC:
        return "guest_interview";
      default:
        return null;
    }
  } else if (inputType === INPUT_TYPE_DEVICE) {
    return "sst_camera_video";
  } else {
    return null;
  }
}

export const outputIcon = (outputType, protocol) => {
  if (outputType === OUTPUT_TYPE_IP && protocol) {
    switch (protocol) {
      case RTSP:
        return "rtsp_cast";
      case RTMP:
        return "rtmp_cast";
      case HLS:
        return "hls_cast";
      case TS:
        return "ts_cast";
      case SRT:
        return "srt_cast";
      case SST:
        return "sst_cast";
      default:
        return null;
    }
  } else if (outputType === OUTPUT_TYPE_NDI) {
    return "ndi_cast";
  } else if (outputType === OUTPUT_TYPE_HARDWARE) {
    return "sdi";
  } else {
    return null;
  }
}

export const formatBitrate = (bitrate) => {
  if(bitrate >= 1000000){
    return `${(bitrate / 1000000).toFixed(1)} Mb/s`;
  }
  return `${(bitrate / 1000).toFixed(0)} Kb/s`;
};

export const formatElapsedTime = (minutes) => {
  if(minutes < 0){
    return null;
  }
  const hours = parseInt(minutes/60);
  const lastingMinutes = minutes % 60;
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = lastingMinutes < 10 ? `0${lastingMinutes}` : `${lastingMinutes}`;
  return `${formattedHours}h${formattedMinutes}m`;
};

export const formatSeconds = (seconds) => {
  let format = 'HH:mm:ss';
  if(seconds){
    format = 'mm:ss';
  }
  return DayJS().startOf('day').add(seconds, 'second').format(format);
};

export const formatMilliToSeconds = (milliseconds) => {
  if (milliseconds > 999) {
    return `${(milliseconds / 1000).toFixed(1)} s`;
  } else {
    return `${milliseconds} ms`;
  }
};

export const formatRTT = (ms) => {
  return `${ms}ms`;
};

export const formatLost = (lost) => {
  return lost > 999 ? `${(lost/1000).toFixed(1)}k` : `${lost}`;
};

export const inputMatchingIPProfile = (ipProfiles, input) => {
  if(!input.ipProfileId) {
    return null;
  }
  return ipProfiles.find(profile => profile.id === input.ipProfileId);
};

export const outputMatchingIPProfile = (ipProfiles, output) => {
  if(!output.ipProfileId) {
    return null;
  }
  return ipProfiles.find(profile => profile.id === output.ipProfileId);
};

export const outputMatchingVideoCard = (videoCards, output) => {
  if(output.videoCardId == null || videoCards == null){
    return null;
  }
  return videoCards.find(videoCard => videoCard.id === output.videoCardId);
};

export const getConfiguratorPort = (input) => {
  return 4000 + 10 * parseInt(input.id) + 4;
};

export const canPlayStream = (ipProfile) => {
  return [HLS].indexOf(ipProfile.protocol) !== -1;
};

export const range = (start, end) => {
  return Array(end - start + 1).fill().map((_, idx) => start + idx);
};

export const isPortInRange = (port, start, end) => {
  return port >= start && port <= end;
};

export const hasFileExtension = (filename, fileExtension) => {
  const extension = filename.split('.').pop();
  return extension === fileExtension;
};

export const apiDocLink = (key) => {
  return `/rest-api/doc/${key}`;
};

export const extractDirectoryPath = (filePath) => {
  if(!filePath) return null;
  return filePath.slice(0, filePath.lastIndexOf('/'));
};

export const fileImagePreviewUrl = (file) => {
  return `/download/${file.token}`;
};

export const fileVideoPreviewUrl = (file) => {
  const extension = file.filename.slice(file.filename.lastIndexOf("."));
  return `/play/${file.token}/video${extension}`;
};

export const reindexProfileConfigList = (list, index) => {
  return  Object.keys(list).reduce((a, b) => {
    a[index] = list[b];
    index++;
    return a;
  }, {});
};

export const isEmpty = (obj) => {
  for(var prop in obj) {
    if(Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
}