import PropTypes from "prop-types";
import React from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import {Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Formik} from "formik";

import {isEmptyString} from "../../../../utils/string-utils";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

const propTypes = {
  config: PropTypes.shape({
    authentificationRetryMax: PropTypes.number
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUnblockAccounts: PropTypes.func.isRequired,
  sslCertificate: PropTypes.string,
  sslPrivateKey: PropTypes.string
};

const RETRY_MIN = 1
const RETRY_MAX = 12

const AdvancedSecuritySettingsForm = (props) => {

  const { config, translate } = props;
  if(!config){
    return null;
  }

  let authRetryMax = config.authentificationRetryMax;
  if(authRetryMax === undefined){
    authRetryMax = 0;
  }

  const handleUnblockAccount = (e) => {
    e.preventDefault();
    props.onUnblockAccounts();
  };

  const handleSubmit = (values, { resetForm }) => {
    let data = { ...values };
    if(!values.authSecurityEnabled){
      data.authentificationRetryMax = 0;
    }
    delete data.authSecurityEnabled;
    props.onSubmit(data);
    resetForm();
  };

  const handleValidation = (values) => {
    const errors = {};

    //Account lock
    if(isEmptyString(values.authentificationRetryMax) && values.authSecurityEnabled === true){
      errors.authentificationRetryMax = 'genericLabel.REQUIRED_FIELD.text';
    } else if (values.authSecurityEnabled === true && (values.authentificationRetryMax < RETRY_MIN || values.authentificationRetryMax > RETRY_MAX)) {
      errors.authentificationRetryMax = 'genericLabel.INVALID_FORMAT.text';
    }

    // SSL certificate
    if (!isEmptyString(values.sslCertificate)) {
      if (!values.sslCertificate.startsWith('-----BEGIN CERTIFICATE-----')
          || !values.sslCertificate.endsWith('-----END CERTIFICATE-----')) {
        errors.sslCertificate = 'genericLabel.INVALID_FORMAT.text';
      }
      if (isEmptyString(values.sslPrivateKey)) {
        errors.sslPrivateKey = 'genericLabel.REQUIRED_FIELD.text';
      }
    }
    if (!isEmptyString(values.sslPrivateKey)) {
      if ((!values.sslPrivateKey.startsWith('-----BEGIN PRIVATE KEY-----') && !values.sslPrivateKey.startsWith('-----BEGIN RSA PRIVATE KEY-----'))
          || (!values.sslPrivateKey.endsWith('-----END PRIVATE KEY-----') && !values.sslPrivateKey.endsWith('-----END RSA PRIVATE KEY-----'))) {
        errors.sslPrivateKey = 'genericLabel.INVALID_FORMAT.text';
      }
      if (isEmptyString(values.sslCertificate)) {
        errors.sslCertificate = 'genericLabel.REQUIRED_FIELD.text';
      }
    }

    return errors;
  };


  return (
    <Formik initialValues={{
              authSecurityEnabled: authRetryMax > 0,
              authentificationRetryMax: authRetryMax,
              sslCertificate: '',
              sslPrivateKey: ''
            }}
            validate={ handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
          /* and other goodies */
        }) => {
          const handleChangeLocking = (e) => {
            handleChange(e);
            if (e.target.value) {
              setFieldValue('authentificationRetryMax', 3);
            }
          };
          return (
            <Form onSubmit={ handleSubmit }>
              <section className="form-section">
                <div className="header">
                  <div className="title secondary">
                    <Translate id="genericLabel.WEB_PAGE_LOGGING_SECURITY.text"/>
                  </div>
                </div>
                <FormGroup check>
                  <Label check>
                    <Input id="security_advanced_lockingEnable" 
                           type="checkbox"
                            name="authSecurityEnabled"
                           onChange={handleChangeLocking}
                           checked={values.authSecurityEnabled}/>{' '}
                    <Translate id="genericLabel.ACCOUNTS_LOCKING.text"/>
                  </Label>
                  <Button className="basic"
                          onClick={handleUnblockAccount}
                          style={{ marginLeft: '1rem' }}>
                    <AWIcon name="lock_open"/>
                  </Button>
                </FormGroup>
                { values.authSecurityEnabled &&
                <FormGroup>
                  <Label for="authentificationRetryMax">
                    <Translate id="genericLabel.MAX_ATTEMPT.text"/>
                  </Label>
                  <Input type="number"
                         name="authentificationRetryMax"
                         id="security_advanced_authentificationRetryMax"
                         max={RETRY_MAX}
                         min={RETRY_MIN}
                         invalid={errors.authentificationRetryMax !== undefined}
                         placeholder={translate('genericLabel.MAX_ATTEMPT.text')}
                         value={values.authentificationRetryMax}
                         onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.authentificationRetryMax}/>
                  </FormFeedback>
                </FormGroup>
                }
                <div className="header">
                  <div className="title secondary">
                    <Translate id="genericLabel.SSL_CERTIFICATE.text"/>
                  </div>
                </div>
                <FormGroup>
                  <Label for="sslCertificate">
                    <div className="indicator">
                      <Translate id="genericLabel.SSL_CERTIFICATE_HELP.text"/>
                    </div>
                  </Label>
                  <Input type="textarea"
                        name="sslCertificate"
                        id="security_advanced_sslCertificate"
                        invalid={errors.sslCertificate !== undefined}
                        placeholder={translate('genericLabel.SSL_CERTIFICATE_PLACEHOLDER.text')}
                        value={values.sslCertificate}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.sslCertificate}/>
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="sslPrivateKey">
                    <div className="indicator">
                      <Translate id="genericLabel.SSL_PRIVATE_KEY_HELP.text"/>
                    </div>
                  </Label>
                  <Input type="textarea"
                        name="sslPrivateKey"
                        id="security_advanced_sslPrivateKey"
                        invalid={errors.sslPrivateKey !== undefined}
                        placeholder={translate('genericLabel.SSL_PRIVATE_KEY_PLACEHOLDER.text')}
                        value={values.sslPrivateKey}
                        onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.sslPrivateKey}/>
                  </FormFeedback>
                </FormGroup>
              </section>
              <FormGroup className="buttons">
                <Button id="security_advanced_saveButton"
                        color="primary"
                        disabled={isSubmitting || !dirty}
                        type="submit">
                  <Translate id="genericLabel.SAVE.text"/>
                </Button>
              </FormGroup>
            </Form>
          )
        }
      }
    </Formik>
  )
};

AdvancedSecuritySettingsForm.propTypes = propTypes;

export default withLocalize(AdvancedSecuritySettingsForm);